.btn-job {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-job:hover {
  background-color: #45a049;
}
.container {
    display: flex;
  }

  .main-column {
    padding:20px;
    width: 100%;
  }

.rowTitles {
  display: grid;
  grid-template-columns: 1fr 4fr 20fr 2fr;
  height: 25px; 
  box-shadow: 0px 0px 10px rgba(146, 138, 138, 0.1);
  margin-bottom: 10px;
}
.rowTitles div {
  padding-left: 8px;
  font-size: bold;
  font-size: 1.2rem;
  border-right: 1px solid gray;
}
.rowTableContainer {
  padding: 10px;;
}
.titleTableContainer {
  padding: 10px;
}
@media screen and (max-width: 767px) {
  .container {
    display: block;
  }
  .nav-column {
    font-size: 0.8rem;
    padding: 0;
  }
  .main-column {
    width: 100%;
    padding: 0 ;
  
    border: 0;
  }
 
  .nav-column {
    border-right: none;
    padding: 0;
  }
}

